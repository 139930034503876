.card {
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.card:hover {
    transform: translateY(-5px);
}

.footer {
    background-color: #3B1921;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

.privacy-text {
    margin-left: 20px;
    margin-right: 20px;
}

.container-bg {
    background-image: url('../public/images/hero.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.screenshots-scroll {
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
}

.screenshots-scroll img {
    display: inline-block;
    max-width: 300px;
    margin-right: 10px;
    border-radius: 10px;
    transition: transform 0.3s;
}

.screenshots-scroll img.scale-down {
    transform: scale(0.8);
}

/* Add this to public/css/styles.css */
#download {
    background-color: #f8f9fa;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#download .btn {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

#download .btn img.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

#download .btn:hover {
    background-color: #007bff;
    color: #fff;
    transform: translateY(-3px);
}